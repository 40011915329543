import clsx from 'clsx';
import { useEffect, useState } from 'react';

export default function LazyImage({
  src,
  defaultImage = getDefaultImageBase64(),
  className,
  removeFade = false,
  ...rest
}) {
  const [imageSrc, setImageSrc] = useState(defaultImage);
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = async () => {
      setIsLoaded(true);
      setImageSrc(src);
    };
  }, [src]);

  return (
    <img
      src={imageSrc}
      className={clsx(
        className,
        !isLoaded && !removeFade && 'grayscale',
        isLoaded && 'animate-fadeIn '
      )}
      {...rest}
    />
  );
}

function getDefaultImageBase64() {
  return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA0CAYAAADWr1sfAAAErklEQVR4Ae2a3U4bRxiGv1lvsLHNn5M4KgnEJkqlqK3USD0hJ02kRipHba+g5QpCrwC4A+6gvYTmqJXSys1J6EEkkEhTlarYxQgapwRjDDFkvdN51wxyyM561+vg9SqPhLC94/U8O3/f7H6MHMgt5YeNmn6POL/NiGc4UYYCCONU5owtk8nv6xHzhzuT2YKyrN2HucV8xuCR74j4bepBGGPfR6g+byeunf7g59+K9+qmttSrsoBz/o3BtaWfHv0zc/rYay38y2Jx1uTmHIUJps3dnRybP3krX6BluWkuUAipE337+a2rlpsljDGLbswZDVM4KevMvIkxreNdnbTZVrIjgzG6mIpROhWnWFSnIFA9OKLK/hGtFStUOzScig43JmG6wxozspZXldR1jSauDNH4ewMUZNa3KrS2USHDMJVl9H5zRBeyXyoLCNlPPkhTMt5HRt2krVKVdvcOrb8gkBD1Gk0nKX0+LhpkkFJDMXr8e0kpfXSgzWhi0fpCdUK0LGQPjwxaflqi9c1KYGTBvujSfxVe0OOVf6km6oi6TlwZVJbXGPtUE9HJx3YHMU5lN1758z9LOqigbk9EHdEL0dKYb+xAtCha2H6ykleqtH0QaFkJ6rj5rGq9xuRqB0Jj5XQ7kOyz/m8+26NugPGZFUNqaCBqyWA44eI7Uak2hhtWktVC2baMWjjeEN5/+YrOmqgYTh++f4H0SCPyjfbpdD2Tooh4j4lThZxfnJZNjQLI6MXkiWwzly8lyS+BFLaTBWhp3+emDoO1O9F/TlSa0Xa5Ru1QU0ySnZg8Oyosx17suCVQwZVVsaQdeqsoxunopTe7NSYuv3SsS5+WtT4Trz8Sn0U9xt5YTxHoYBLCa8TJ+Y1yy1naDR1pYXTjG9dSr8lKIH1jIkVPVhuBgVusYGL1OXUa3y0MWbRsor9PWQZravMy00181cCNrCQo0r5+PXt5yJWsxIqexrp7j6Ft4etXRyh9IUFewVYOUVO3aEu4XVlJN6U9C4+J7ZcfWQmkca6zxpMwKjg+2rlK4lxnLe1auNOyErfSWBGwVfSLq8DjbclK5LmLW5WWv4+A5I+/t8Xtnfa2rS1b+G3LSlQtffr3rcjt2nnP4aqkpfBZyDb/VrO06mK3G6ODYNxRbwKCuGOBDYPTxZbSXndjgbwBYN1ndtGz2mnpQAp7wat0zwsDL9KhEAZy392K0AiDRLz1zi1Uwm54Jyyp1YL/PElFVTwkV6EULu28pF6l4hBna4yoYHfg+YveFV4r7qoOlTXOWMHuyE6lRutb3Xly6AekPqjzPdgyHog/VH15bWOX9g7U4yFoIMkFeR5q+H2Wy+WHjai2oyoSpqQWg5lZK0/rweJ6DgmkTifEDgbiA8lzJ8+Ouw26bknMNZhvMASdQP7lZ5Pj01bwaVB9WufaEjnkauHkT8Wdhp6EU/kVq1vph9ayNIWsU2bOU1gRblPHmbUn6/DdW9kF4uGTNoWT5XbMG/nSDx7lZ4hrs9TreZeiG6Nlm2WBbYL4j4v5TITTHCPta+pJ2K8Gq09P2SSIM6evQVxHaiKy9ZDAFtBWR7TISQRQvP6wGqeFr25my6qy/wM4G8EqUGGTcgAAAABJRU5ErkJggg==';
}
